<template>
	<div class="container" v-if="device">
		<div class="card card-body" v-if="deviceReturnedToCustomer">
			<empty-state
				statusIcon="success"
				title="This repair has been completed"
			></empty-state>
		</div>

		<div class="card card-body" v-if="displayDeviceStatus">
			<empty-state
					icon="tools"
					iconClass="icon-all-muted"
					:title="deviceStatusText.title"
					:subtitle="deviceStatusText.subtitle"
			>
				<slot>
					<div class="d-flex" v-if="repairIsComplete">
						<button class="btn btn-lg continue-btn mb-4 mx-auto" @click="receiveDeviceFromTechnician()">Device received from technician</button>
					</div>
					<div class="d-flex" v-if="deviceReturnedToStore">
						<button class="btn btn-lg continue-btn mb-4 mx-auto" @click="returnDeviceToCustomer()">Device returned to customer</button>
					</div>
				</slot>
			</empty-state>
		</div>

		<!-- Job Details -->
		<div id="job-details" class="card card-body pb-0" v-if="order">
			<h5>Repair details</h5>
			<dl>
				<dt>Order ID</dt>
				<dd>{{ order.reference }}</dd>
				<dt>Customer's name</dt>
				<dd>{{ order.first_name }} {{ order.second_name }}</dd>
				<dt>Customer's phone number</dt>
				<dd>
					<template v-if="order.phone_number">{{ order.phone_number }}</template>
					<template v-if="!order.phone_number">Not supplied</template>
				</dd>
				<dt>Repair address</dt>
				<dd>{{ order.address_string }}</dd>
				<dt>Repair date</dt>
				<dd>
					<template v-if="order.job_date">{{ order.job_date }}</template>
					<template v-if="!order.job_date">--</template>
				</dd>
			</dl>
		</div>

		<!-- Device Details -->
		<div id="device-details" class="card card-body pb-0">
			<h5>Device details</h5>
			<div class="media">
				<div class="media-left">
					<img v-if="device.image" :src="device.image" class="img-2x">
					<template v-if="!device.image">
						<icon-mobile v-if="device.type === 'phone'" size="2x" class="icon-all-muted"></icon-mobile>
						<icon-tablet v-if="device.type === 'tablet'" size="2x" class="icon-all-muted"></icon-tablet>
					</template>
				</div>
				<div class="media-body">
					<h5 class="mb-2">{{ device.model_title }}</h5>
					<p class="mb-0 text-muted text-sm">IMEI: <template v-if="device.imei">{{ device.imei }}</template><template v-if="!device.imei">Unavailable</template></p>
<!--					<p class="mb-0 mt-1 text-muted text-sm mb-3" v-if="device.board">Board: {{ device.board }}</p>-->
					<ul class="list-unstyled mt-2 text-muted text-sm">
						<li v-for="(problem, i) in device.problems_selected" :key="i">
							{{ problem.title }}
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div v-if="!deviceReturnedToCustomer">
			<div class="d-flex" v-if="deviceIsNotYetReceived">
				<button class="btn btn-lg continue-btn mb-4 mx-auto" @click="receiveDeviceAtStore()">Book in device &amp; print label</button>
			</div>

			<div class="d-flex">
				<button class="btn btn-link text-primary mb-4 mx-auto" @click="printLabel()">Re-print label</button>
			</div>
		</div>

		<div id="printQrCode" hidden>
			<h5 class="mb-5">Print this device code and attach or include it with the device's packaging.</h5>
			<qrcode-vue  :value="device.qr_code" :size="200" level="H" renderAs="svg"></qrcode-vue>
		</div>

	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import QrcodeVue from 'qrcode.vue';

export default {
	components: {
		QrcodeVue,
	},

	mounted() {
		if (!this.order || !this.device) {
			this.$router.push({name: `repairSearch`});
		}
		this.loadDevice(this.device.asset_reference);
	},

	computed: {
		...mapGetters(['device', 'order']),

		deviceIsNotYetReceived() {
			return this.device && this.device.status.code === "not-yet-received";
		},

		displayDeviceStatus() {
			return this.device && (
				this.device.status.code !== "not-yet-received" &&
				this.device.status.code !== "returned-to-customer"
			);
		},

		repairIsComplete() {
			return this.device && this.device.status.code === "repair-complete";
		},

		deviceReturnedToStore() {
			return (
				this.device &&
				this.device.status.code === "returned-to-location"
			);
		},

		deviceReturnedToCustomer() {
			return (
				this.device &&
				this.device.status.code === "returned-to-customer"
			);
		},

		deviceStatusText() {
			switch (this.device.status.code) {
				case 'received-at-location':
					return {
						title: 'The device has been received',
						subtitle: 'The device has been received from the customer and is awaiting collection by the technician'
					};

				case 'technician-received-pending-repair':
					return {
						title: 'The device is with the technician',
						subtitle: 'The device is with the technician and is pending repair'
					};

				case 'repair-in-progress':
					return {
						title: 'This device is currently being repaired',
						subtitle: 'The device is with the technician is currently being repaired'
					};

				case 'repair-complete':
					return {
						title: 'The device has been repaired',
						subtitle: 'Use the button below once the technician has returned the device'
					};

				case 'returned-to-location':
					return {
						title: 'The device is ready to be returned to the customer',
						subtitle: 'Use the button below to confirm that the device has been returned to the customer'
					};

				default:
					return {
						title: 'Pending device update',
						subtitle: 'This device is pending a status update. Contact WeFix customer services'
					};
			}
		}
	},

	methods: {
		...mapActions(['displayToast', 'loadDevice', 'updateDeviceStatus']),

		receiveDeviceAtStore() {
			this.updateDeviceStatus('received-at-location').then(() => {
				this.printLabel();

			}).catch(this.handleUpdateError)
		},

		receiveDeviceFromTechnician() {
			this.updateDeviceStatus('returned-to-location').then(() => {
				this.displayToast({text: 'The device has been successfully received from the technician', type: 'success'});

			}).catch(this.handleUpdateError)
		},

		returnDeviceToCustomer() {
			this.updateDeviceStatus('returned-to-customer').then(() => {
				this.displayToast({text: 'The device has been successfully returned to the customer', type: 'success'});

			}).catch(this.handleUpdateError)
		},

		handleUpdateError(e) {
			this.displayToast({text: e.response.data.messages[0], type: 'error'});
			this.$router.push({name: `repairSearch`});
		},

		printLabel() {
			this.$htmlToPaper('printQrCode');
		}
	}
}
</script>

<style scoped>

</style>